import { createUploadedFileFromFile } from "@/utils";
import { Money } from "@/utils/api-client";
import { Phone } from "@/utils/api-client/models/common/Phone";
import { UploadedFile } from "@/utils/api-client/models/upload/UploadedFile";
import { createProjectRequestManager } from "@/utils/di";

type General = {
  readonly company: {
    readonly name: string;
    readonly industry: string;
    readonly registrationNumber: string;
    readonly siteUri?: string;
    readonly address: string;
    readonly contactPerson: string;
    readonly phone: string;
    readonly description?: string;
    readonly files: Array<UploadedFile>;
  };
  readonly loan: {
    readonly amount: string;
    readonly initialTerm: number;
    readonly maturityDate?: Date;
    readonly purpose: string;
  };
}

export class GeneralManager {
  async getOne(uuid: string): Promise<General> {
    const manager = await createProjectRequestManager();
    const data = await manager.getGeneral(uuid);
    const { phone, files, ...company} = data.company
    const { sum, maturityDate, ...loan} = data.loan
    return {
      company: {
        ...company,
        phone:  "+" + phone.countryCode + phone.number,
        files: (files || []).map((file) => createUploadedFileFromFile(file))
      },
      loan: {
        ...loan,
        amount: Math.floor(sum.amount / 100).toString(),
        maturityDate: maturityDate ? new Date(maturityDate) : undefined
      }
    };
  }

  async save(uuid: string, general: General): Promise<void> {
    const { phone, files, ...company} = general.company
    const phoneCountryCode = "7";
    const phoneNumber = phone.replace(/\D/g, "").substr(1);

    const { amount, maturityDate, ...loan} = general.loan
    if (!maturityDate) {
      throw new Error("Invalid data");
    }

    const manager = await createProjectRequestManager();
    return manager.setGeneral(uuid, {
      company: {
        ...company,
        phone: new Phone(phoneCountryCode, phoneNumber),
        files: (files || []).map(({ url, name }) => ({ url, name }))
      },
      loan: {
        ...loan,
        sum: new Money(
          parseInt(amount) * 100,
          "RUB"
        ),
        maturityDate: maturityDate.toISOString()
      }
    });
  }
}
