<template>
  <div class="my-project-requests-request-general">
    <data-loading :loading="dataLoading" :lock="true"/>
    <form @input="validateForm" @change="validateForm" @submit.stop.prevent="submitForm">
      <div class="form-group">
        <a @click.stop.prevent="searchCompany" class="text-success float-right" href="#">Изменить</a>
        <label class="text-muted" for="company-name">Наименование компании</label>
        <input v-model="general.company.name"
               class="form-control form-control-lg"
               id="company-name"
               type="text"
               placeholder="Наименование компании"
               required>
        <input-errors :errors="errors['company.name']"/>
      </div>
      <div class="form-group">
        <label class="text-muted" for="company-industry">Вид деятельности</label>
        <input v-model="general.company.industry"
               class="form-control form-control-lg"
               id="company-industry"
               type="text"
               placeholder="Вид деятельности"
               required>
        <input-errors :errors="errors['company.industry']"/>
      </div>
      <div class="row">
        <div class="col-12 col-md-5">
          <div class="form-group">
            <label class="text-muted" for="company-registration-number">ИНН</label>
            <input-digits v-model="general.company.registrationNumber"
                          maxlength="12"
                          class="form-control form-control-lg"
                          id="company-registration-number"
                          placeholder="000000000000"
                          required/>
            <input-errors :errors="errors['company.registrationNumber']"/>
          </div>
        </div>
        <div class="col-12 col-md-7">
          <div class="form-group">
            <label class="text-muted" for="company-site-uri">Сайт</label>
            <input v-model="general.company.siteUri"
                   class="form-control form-control-lg"
                   id="company-site-uri"
                   type="url"
                   placeholder="https://...">
            <input-errors :errors="errors['company.siteUri']"/>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="text-muted" for="company-address">Адрес</label>
        <input v-model="general.company.address"
               class="form-control form-control-lg"
               id="company-address"
               type="text"
               placeholder="Адрес"
               required>
        <input-errors :errors="errors['company.address']"/>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label class="text-muted" for="company-contact-person">Контактное лицо</label>
            <input v-model="general.company.contactPerson"
                   class="form-control form-control-lg"
                   id="company-contact-person"
                   type="text"
                   placeholder="ФИО"
                   required>
            <input-errors :errors="errors['company.contactPerson']"/>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label class="text-muted" for="company-phone">Телефон</label>
            <input-mask v-model="general.company.phone"
                        mask="+7 (999) 999-9999"
                        class="form-control form-control-lg"
                        id="company-phone"
                        placeholder="+7 (000) 000-0000"
                        required/>
            <input-errors :errors="errors['company.phone']"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-7">
          <div class="form-group">
            <label class="text-muted" for="company-description">Расскажите о своей компании или прикрепите презентацию</label>
            <textarea v-model="general.company.description"
                      class="form-control form-control-lg"
                      id="company-description"
                      placeholder="Описание компании"
            ></textarea>
            <input-errors :errors="errors['company.description']"/>
          </div>
        </div>
        <div class="col-12 col-md-5">
          <div class="form-group">
            <file-upload :files="general.company.files"/>
            <input-errors :errors="errors['company.files']"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4 col-lg-3">
          <div class="form-group">
            <label class="text-muted" for="loan-sum">Сумма займа</label>
            <div class="input-group">
              <input-money v-model="general.loan.amount"
                           class="form-control"
                           id="loan-sum"
                           placeholder="10 000 000"
                           required/>
              <div class="input-group-append">
                <div class="input-group-text">руб</div>
              </div>
            </div>
            <input-errors :errors="errors['loan.amount']"/>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-3">
          <div class="form-group">
            <label class="text-muted" for="loan-term">Срок займа</label>
            <div class="input-group">
              <input v-model="general.loan.initialTerm"
                     class="form-control"
                     id="loan-term"
                     type="number"
                     min="1"
                     max="36"
                     step="1"
                     placeholder="36"
                     required>
              <div class="input-group-append">
                <div class="input-group-text">мес</div>
              </div>
            </div>
            <input-errors :errors="errors['loan.initialTerm']"/>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-3">
          <div class="form-group">
            <label class="text-muted" for="loan-maturity-date">Дата к которой нужны средства</label>
            <date-picker v-model="general.loan.maturityDate" locale="ru">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <i class="bi-calendar"></i>
                    </div>
                  </div>
                  <input :value="inputValue"
                         v-on="inputEvents"
                         class="form-control"
                         id="loan-maturity-date"
                         :placeholder="maturityDatePlaceholder"
                         autocomplete="off"
                         required>
                </div>
              </template>
            </date-picker>
          </div>
          <input-errors :errors="errors['loan.maturityDate']"/>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-10">
          <div class="form-group">
            <label class="text-muted" for="loan-purpose">Цель займа</label>
            <textarea v-model="general.loan.purpose"
                      class="form-control form-control-lg"
                      id="loan-purpose"
                      placeholder="Цель займа"
            ></textarea>
            <input-errors :errors="errors['loan.purpose']"/>
          </div>
        </div>
      </div>
      <hr>
      <div class="form-group text-right">
        <button type="submit" class="btn btn-lg btn-success">Продолжить</button>
      </div>
    </form>
    <company-search :show="searchCompanyOpen"
                    @choose="chooseCompany"
                    @close="searchCompanyClose"/>
    <dialog-modal ref="dialog">
      <p v-if="callbackSent">
        Спасибо, наш менеджер свяжется с Вами в ближайшее время.
      </p>
      <p v-else>
        Вы зарегистрировались в качестве заемщика на Инвестиционной платформе Lendly.<br>
        Можете самостоятельно подать заявку или обратиться к менеджеру платформы, который проконсультирует Вас и поможет заполнить заявку.
      </p>
      <template v-slot:button-action>
        <button v-if="!callbackSent" @click="callbackRequest" type="button" class="btn btn-success">
          Связаться со мной
        </button>
      </template>
    </dialog-modal>
    <processing :show="processing"/>
  </div>
</template>

<script>
import { CompanySearch, DataLoading, DialogModal, FileUpload, InputDigits, InputErrors, InputMoney, Processing } from "@/components/common";
import { createCallbackRequestManager } from "@/utils/di";
import moment from "moment";
import InputMask from "primevue/inputmask";
import { DatePicker } from "v-calendar";
import { GeneralManager } from "../managers/GeneralManager";

const now = moment();
const generalManager = new GeneralManager();

export default {
  name: "General",
  components: {
    CompanySearch,
    DataLoading,
    DatePicker,
    DialogModal,
    FileUpload,
    InputDigits,
    InputMask,
    InputMoney,
    InputErrors,
    Processing
  },
  inject: ["profile"],
  async mounted() {
    if (this.profile.firstProjectRequestUuid) {
      this.$refs.dialog.open();
    }

    this.dataLoading = true;
    try {
      Object.assign(this.general, await generalManager.getOne(this.uuid));
      this.validateForm();
    } finally {
      this.dataLoading = false;
    }
  },
  data() {
    return {
      general: {
        company: {
          files: []
        },
        loan: {}
      },
      errors: {},
      dataLoading: false,
      processing: false,
      searchCompanyOpen: false,
      callbackSent: false
    };
  },
  computed: {
    uuid() {
      return this.$route.params.uuid;
    },
    maturityDatePlaceholder() {
      return now.add(3, "months").format("DD.MM.YYYY");
    }
  },
  methods: {
    validateForm() {
      const numberRequiredFields = 11; // 10 + button
      const errors = {};
      if (!this.general.company.name) {
        errors['company.name'] = ["Поле обязательное для заполнения"];
      }
      if (!this.general.company.industry) {
        errors['company.industry'] = ["Поле обязательное для заполнения"];
      }
      if (!this.general.company.registrationNumber) {
        errors['company.registrationNumber'] = ["Поле обязательное для заполнения"];
      }
      if (!this.general.company.address) {
        errors['company.address'] = ["Поле обязательное для заполнения"];
      }
      if (!this.general.company.contactPerson) {
        errors['company.contactPerson'] = ["Поле обязательное для заполнения"];
      }
      if (!this.general.company.phone) {
        errors['company.phone'] = ["Поле обязательное для заполнения"];
      }
      if (!this.general.loan.amount) {
        errors['loan.amount'] = ["Поле обязательное для заполнения"];
      }
      if (!this.general.loan.initialTerm) {
        errors['loan.initialTerm'] = ["Поле обязательное для заполнения"];
      }
      if (!this.general.loan.maturityDate) {
        errors['loan.maturityDate'] = ["Поле обязательное для заполнения"];
      }
      if (!this.general.loan.purpose) {
        errors['loan.purpose'] = ["Поле обязательное для заполнения"];
      }
      this.errors = errors;
      const numberErrors = Object.keys(errors).length;
      this.$emit("progress", (numberRequiredFields - numberErrors - 1) / numberRequiredFields);
      return numberErrors === 0;
    },
    async submitForm() {
      if (this.validateForm()) {
        this.processing = true;
        try {
          await generalManager.save(this.uuid, this.general);
          await this.$router.push({ name: "myProjectRequests.request.ownership", params: { uuid: this.uuid } });
        } finally {
          this.processing = false;
        }
      }
    },
    searchCompany() {
      this.searchCompanyOpen = true;
    },
    searchCompanyClose() {
      this.searchCompanyOpen = false;
    },
    chooseCompany(company) {
      this.general.company.name = company.name;
      this.general.company.registrationNumber = company.inn;
      this.general.company.address = company.address;
      this.general.company.contactPerson = company.contactPerson;
    },
    async callbackRequest() {
      this.processing = true;
      try {
        const manager = await createCallbackRequestManager();
        await manager.create({ projectUuid: this.uuid });
        this.callbackSent = true;
      } finally {
        this.processing = false;
      }
    }
  }
}
</script>

<style lang="scss">
.my-project-requests-request-general {
  .dialog-modal {
    .modal-dialog {
      max-width: 800px;

      .modal-body {
        p {
          font-size: 1.2rem;
          text-align: center;
          margin-top: 2rem;
        }
      }
    }
  }
}
</style>
